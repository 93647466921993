import React from 'react';

function NotFoundComponent() {
    return (
        <div id="home" className="container-fluid error justify-content-center align-items-center ">
                <div className="row">
                    <h2 className="titlename">Page Not Found !</h2>
                </div>
        </div>
    )
}

export default NotFoundComponent;